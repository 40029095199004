import React from 'react'
import { NextPage } from 'next'
import ErrorPageLayout from '@/components/ErrorPage'
import useAuth from '@/hooks/useAuth'
import { LoadingSpinner } from '@packages/ui-components'
import Login from './login'

const Error404: NextPage = () => {
  const auth = useAuth()

  if (auth.isLoading) {
    return <LoadingSpinner />
  }

  if (!auth.isLoggedIn) {
    return <Login />
  }

  return (
    <ErrorPageLayout
      errorCode={404}
      title="PAGE NOT FOUND"
      subTitle="ERROR CODE: 404"
      text="Oops... looks like you've come off the beaten path. To ensure your finances are on track,
      please head back to your dashboard."
    />
  )
}
export default Error404
